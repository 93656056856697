// @import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@100;300;400;500;60;7000&display=swap');
// .home-wrapper {
//   @include d-x-center;
//   flex-direction: column;
//   background-color: $bg-color;
// }

// .btn-primary {
//   background-color: $primary;
//   height: 38px;
//   border-radius: 8px;
//   padding: 10px 50px;
//   @include d-center;
//   color: $white;
//   &:hover {
//     color: $white;
//   }
// }

.custom-card-hoverable:hover {
  background-color: #e6f7ff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

// register-page
// .register-page {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background: #f0f2f5;
//   min-height: 100vh;
//   &-form {
//     padding: 30px;
//     background-color: $white;
//     border-radius: 5px;
//     width: 450px;
//     .ant-input {
//       padding-left: 10px;
//     }
//     .ant-input-affix-wrapper > input.ant-input {
//       padding-left: 10px !important;
//     }
//     h2 {
//       text-align: center;
//     }
//     &_button {
//       width: 100%;
//     }
//   }
//   .ant-form-item:last-child {
//     margin-bottom: 0 !important;
//   }
//   .ant-btn-primary {
//     width: 100%;
//   }
//   .link-login {
//     margin-top: 15px;
//     font-size: 14px;
//     text-align: center;
//   }
// }

// // ?navbar

// .ant-dropdown-menu-title-content {
//   .anticon {
//     padding-right: 7px;
//   }
// }

// .ant-dropdown-menu-item-divider {
//   margin: 0;
// }

// .ant-dropdown-menu-item,
// .ant-dropdown-menu-submenu-title {
//   padding: 7px 25px 7px 13px;
// }

// .ant-dropdown-menu {
//   border-radius: 5px;
//   top: 30px;
// }

// .ant-dropdown-placement-bottomCenter {
//   width: 140px;
// }

// .ant-dropdown-menu-title-content {
//   img {
//     height: 15px;
//   }
// }

// .ant-layout-has-sider {
//   min-height: calc(100vh - 64px);
// }

// .layout-page-content {
//   padding: 30px;
// }

// .truthy-breadcrumb {
//   .links {
//     font-size: 14px;
//     // color: $primary;
//   }
//   .ant-breadcrumb-separator {
//     font-size: 14px;
//     color: $text-color;
//   }
//   .current {
//     font-size: 14px;
//     &.active {
//       color: $primary;
//     }
//   }
// }

// .truthy-content-header {
//   margin-bottom: 20px;
//   margin-top: 20px;
//   .ant-input-search {
//     position: relative;
//   }
//   .ant-input-affix-wrapper > input.ant-input {
//     padding-left: 10px;
//   }
//   .ant-input-group {
//     position: relative;
//     display: flex;
//     align-items: center;
//   }
//   .ant-input-group-addon {
//     position: absolute;
//     right: 32px;
//     left: auto !important;
//     border: 0 !important;
//     height: 100%;
//     top: 1px;
//     background-color: transparent;
//     z-index: 9;
//     button {
//       border: 0;
//       height: 42px;
//     }
//   }
// }

// .mb-0 {
//   margin-bottom: 0 !important;
// }

// .home-page {
//   background-color: #ececec;
//   position: relative;
//   .side-img{
//     position: absolute;
//   }
// }
// .w-100 {
//   width: 100%;
// }


// .layout-page-content > :nth-child(2){
//   flex: initial !important;
// }

// .ant-card {
//   border-radius: 8px;
// }

// .banner{
//   background-color: #ececec;
//   min-height: calc(100vh - 230px);
//   display: flex;
//   align-items: center;
// }

// img{
//   max-width: 100%;
//   height: auto;
// }

// .container-fluid{
//   padding-left: 30px;
//   padding-right: 30px;
// }

// .text-right{
//   text-align: right;
// }
// .text-left{
//   text-align: left;
// }

// .text-center{
//   text-align: center;
// }

// .container{
//   max-width: 	1200px;
//   width: 100%;
//   padding-right: 15px;
//   padding-left: 15px;
//   margin-right: auto;
//   margin-left: auto;
// }

// .align-items-center{
//   align-items: center;
// }

// // banner 
// .banner-content{
//   h2{
//     font-weight:$bold ;
//     font-size: 45px;
//     line-height: 50px;
//   }
//   p{
//     margin: 15px 0 25px;
//     font-size: 15px;
//   }
//   .round{
//     border-radius: 50px;
//     padding: 0 34px;
//     box-shadow: 2px 3px 6px rgba(57, 175, 159, 0.32);
//   }
// }

// // /helps oontent
// .home-page{
//   font-family: "Public Sans", sans-serif;;
// }

// .truthy-helps {
//   .ant-col {
//     padding-left: 35px !important;
//     padding-right: 35px !important;
//   }
// }
// .truthy-help-content{
//   // background: url('../../images/helps-bg.jpg') no-repeat center center fixed;
//   // background: url('assets/images/helps-bg.jpg') no-repeat center center fixed;
//   background-size: cover;
//   // height: 200px;
//   padding: 60px 0;
//   h3{
//     margin-bottom: 50px;
//   }
//   .card-inner{
//     text-align: center;
//     .img-wrap{
//       svg{
//         font-size: 50px;
//         margin-bottom: 20px;
//       }
//     }
//     h4{
//       font-size: 1.25rem;
//       font-weight: 700;
//       margin:10px 0;
//     }
//     p{
//       line-height: 1.5;
//       font-size: 1rem;
//       font-weight: 400;
//       color: rgb(99, 115, 129);
//     }
//   }
//   .ant-card{
//     background-color: rgb(244, 246, 248);
//     padding: 25px;
//     box-shadow: rgba(145, 158, 171, 0.40) -6px 7px 20px 0px;
//     margin-bottom: 40px;
//     .img-wrap{
//       svg{
//         color: $primary;
//       }
//     }
//     min-height: 350px;
//   }
//   .card-one{

//     // margin-top: -40px;
//   }
//   .card-two{
//     background-color: rgb(255, 255, 255);
//     box-shadow: rgba(145, 158, 171, 0.40) -6px 7px 20px 0px;

//   }
//   .card-three{
//     margin-top: 80px;
//     .img-wrap{
//       svg{
//         color:#f5b74e;
//       }
//     }
//   }

// }

// .heading{
//   font-size: 30px;
//   font-weight: bold;
//   margin-bottom: 65px;
// }

// .contributors{
//   margin: 40px 0;
//   width: 100%;
//   .ant-card{
//     padding: 20px 40px 60px;
//     width: 100%;
//     cursor: pointer;
//     // background-color: transparent;
//     border: 0;
//   }
//   .card-contibution{
//     position: relative;
//     box-shadow: 0px 8px 45px rgba(210, 210, 215, 0.9);
//     &:hover{
//       .designation{
//         // opacity: 1;
//       }
//     }
//   }
//   .ant-card-body{
//     padding: 20px 0 5px;
//   }
//   .card-inner{
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     flex-direction: column;
//     h4{
//       font-size: 22px;
//       font-weight: 600;
//       text-align: center;
//     }
//     .designation{
//       position: absolute;
//       bottom: 0;
//       opacity: 1;
//       padding-bottom: 25px;
//       transition: all ease .5s;
//       @include d-y-center;
//       .icon{
//         cursor: pointer;
//         svg{
//          margin-right: 10px;
//           font-size: 23px;
//           color: #000000;
//         }
//       }
//       .linked{
//         svg{
//           color: #0084be;
//         }
//       }
//     }


//   }
// }


// .footer{
//  background:#383838;
//  width: 100%;
//  padding: 20px;
//  color:#ffffff;
// }
// .mb-3{
//   margin-bottom: 20px;
// }