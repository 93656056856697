.order-details-card {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    margin: 5px 0;
}

.order-details-card-cell {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.order-details-card-cell-name {
    margin-left: 10px;
}

.header-cell {
    font-weight: 500;
}

.n3efxUfcgl {
    padding: 0 20px;
    margin: 0;
}

.Mic5QMQpUW {
    display: flex;
    align-items: center;
    font-size: 16px !important;
}

.Mic5QMQpUW.disable {
    color: #ccc;
}

.pOPcZNbZdV {
    font-size: 18px;
    margin-right: 5px;
}

.display-none {
    display: none;
}

.XTYSPYSFZ0 {
    display: flex;
    align-items: center;
}