.tab-in-modal {
    margin-top: -25px;
}

.warning-del-p {
    color: red;
}

.warning-del-span {
    font-weight: 500;
    color: rgb(0, 0, 0);
}