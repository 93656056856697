.prod-class-list-data {
    height: 40px;
    display: flex;
}

.end-sale {
    margin-top: 29px;
}

@media (max-width: 575px) {
    .end-sale {
        margin-top: -10px;
    }
}