// ************* COLORS  PALLETS **************** //


// Blue Pallets Color 

$primary: #2069b3;
$primary-dark: #144677;
$primary-light: #4494d4;
$secondary: #6C757D;
$secondary-dark: #495057;
$secondary-light: #ADB5BD;
$white: #ffffff;
$bg-color:#fff;
$success-color: #28A745;
$error-color: #DC3545;
$warning-color: #FFC107;
$info-color: #17A2B8;
$text-color: #333;
$link-color: #2069b3;
$normal-border-color: #ccc;

// $border-color:#d4d5d6;

// // Red paalets
// $danger: #611a15;
// $danger-bg: #fdecea;
// $danger-icon: #f44336;


//

// $text-color: #000000d9;



// font sizes
$font-md: 1.375rem;
$font-sm: 1.25rem;
$font-regular: 1rem;
$font-xs: 0.875rem;
$font-xxs: 0.625rem;


// font-weight
$light: 300;
$regular: 400;
$semi-bold: 600;
$bold: 700;


// font-path 
$open-sans: 'Open Sans', sans-serif;


// media queries 
$screen-sm-max: 575px; // Small tablets and large smartphones (landscape view)
$screen-md-max: 767px; // Small tablets (portrait view)
$screen-lg-max: 991px; // Tablets and small desktops
$screen-xl-max: 1200px; // Large tablets and desktops