// Login Page SCSS

.login-page {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: $primary;

    .ant-form {
        width: 300px;
    }

    .ant-row {
        width: 100%;
    }

    &-form {
        padding: 30px;
        background-color: $white;
        border-radius: 5px;

        h3 {
            text-transform: capitalize;
        }
    }

    .link {
        text-align: center;
        display: block;
        text-transform: capitalize;
    }

    .ant-btn-primary {
        height: 70%;
        width: 100%;
    }
}

.remember-me-check {
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: $primary;
        border-color: $primary;
    }
}

.login-card {
    border-radius: 15px;
}

.login-form {
    max-width: 300px;
}

.login-form-forgot {
    float: right;
}

.login-form-button {
    width: 100%;
    background-color: $primary;
    border: 1px solid $primary;
}

.login-form-button:hover {
    background-color: $primary-light;
}

.return-text {
    float: right;
    margin-right: 5%;
}

.login-right {
    width: 100%;
    float: right;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer {
    position: absolute;
    bottom: 10px;
    display: block;
}